import { useCallback, useEffect, useState, useMemo } from "react";
import { getAuth, getIdToken, onAuthStateChanged } from "firebase/auth";
import moment from "moment";

import { useEnv } from "../../context/EnvContext";
import { postData } from "../../utils/fetch";

import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import Loader from "../../components/shared/Loader";
import UserDataTable, {
  AvatarCell,
  StatusPill,
} from "../../components/UserDataTable";
import UserTableAction from "../../components/UserTableAction";

export default function Dashboard() {
  const [users, setUsers] = useState([]);
  const [daterange, setDaterange] = useState("7");
  const [loader, setLoader] = useState(false);
  const auth = getAuth();
  const { env } = useEnv();

  const fetchUsers = useCallback(
    (enviroment: string, range: string) => {
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          setLoader(true);
          let idToken = await getIdToken(user);
          let data = { range: range };
          let result = await postData(
            process.env.REACT_APP_API_URL + "/users/" + enviroment,
            idToken,
            data
          );
          setLoader(false);
          if (result.status === "success") {
            setUsers(result.data);
          }
        }
      });
    },
    [auth]
  );

  const onMakeFree = useCallback(
    (uid: string) => {
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          let idToken = await getIdToken(user);
          let data = { uid: uid };
          let result = await postData(
            process.env.REACT_APP_API_URL +
              "/users/" +
              env +
              "/change/status/free",
            idToken,
            data
          );
          if (result.status === "success") {
            fetchUsers(env, daterange);
          }
        }
      });
    },
    [auth, daterange, env, fetchUsers]
  );

  useEffect(() => {
    fetchUsers(env, daterange);
  }, [env, daterange, fetchUsers]);

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: AvatarCell,
        imgAccessor: "avatar",
        emailAccessor: "email",
      },
      {
        accessor: "email",
      },
      {
        Header: "User ID",
        accessor: "uid",
        disableGlobalFilter: true,
      },
      {
        Header: "Organization",
        accessor: "organization",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: StatusPill,
      },
      {
        Header: "Joined on",
        accessor: (row: any) => new Date(row.createdAt).getTime(),
        Cell: ({ value }: any) => moment(value).format("DD MMMM YYYY HH:MM A"),
      },
      {
        Header: "Last Activity",
        accessor: (row: any) => new Date(row.updatedAt).getTime(),
        Cell: ({ value }: any) => moment(value).format("DD MMMM YYYY HH:MM A"),
      },
      {
        id: "action",
        accessor: "uid",
        Cell: ({ row }: any) => (
          <UserTableAction row={row.original} handleMakeFree={onMakeFree} />
        ),
      },
    ],
    [onMakeFree]
  );

  return (
    <>
      <Sidebar />
      <Header title="Dashboard" />
      <div className="block pl-20 pr-8 ml-2">
        <section className="w-full pb-8">
          <div className="relative flex flex-col min-w-0 break-words w-full shadow-lg rounded bg-slate-900 text-white overflow-hidden">
            <div className="block w-full overflow-x-auto pt-4">
              <div className="sm:flex sm:gap-x-2 sm:justify-between pb-4 px-4 items-center">
                <div className="px-2 max-w-full">
                  <h3 className="font-semibold text-lg text-white">
                    Users Data
                  </h3>
                </div>

                <div className="flex px-4 gap-x-2 items-baseline">
                  <span className="text-gray-500">Joined in: </span>
                  <select
                    className="bg-slate-900 text-sm border border-slate-700 mt-1 rounded-md py-2 px-4 outline-none"
                    value={daterange}
                    onChange={(e) => {
                      setDaterange(e.target.value);
                    }}
                  >
                    <option value="7">Last 7 days</option>
                    <option value="30">Last 30 days</option>
                    <option value="">All Time</option>
                  </select>
                </div>
              </div>

              {loader ? (
                <Loader />
              ) : (
                <UserDataTable
                  columns={columns}
                  data={users}
                  hidden={["email"]}
                />
              )}
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
