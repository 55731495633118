import { PowerOffIcon } from "./shared/Icons";
import { useAuth } from "../context/AuthContext";
import EnvSelector from "./EnvSelector";

export default function Header(props: { title: string }) {
  const { signout, admin } = useAuth();
  const { title } = props;
  return (
    <div className="flex flex-row flex-wrap flex-1 flex-grow content-start pl-20">
      <div className="h-40 lg:h-20 w-full flex flex-wrap">
        <nav className="w-full lg:max-w-sm flex items-center border-b-1 border-gray-300 order-2 lg:order-1">
          <div className="px-2 w-full">
            <h1 className="text-white text-3xl font-bold">{title}</h1>
          </div>
        </nav>
        <nav className="w-auto flex-1 border-b-1 border-gray-300 order-1 lg:order-2 pr-4">
          <div className="flex h-full justify-end items-center">
            <div className="relative w-64 px-6">
              <EnvSelector />
            </div>
            <button className="flex items-center focus:outline-none mr-3">
              {admin?.photoUrl ? (
                <img
                  className="w-9 h-9 rounded-full mr-4"
                  src={admin?.photoUrl}
                  alt={admin?.displayName}
                  title={admin?.displayName}
                  referrerPolicy="no-referrer"
                />
              ) : (
                <img
                  className="w-9 h-9 rounded-full mr-4"
                  src="/images/mascot.png"
                  alt={admin?.displayName}
                  title={admin?.displayName}
                  referrerPolicy="no-referrer"
                />
              )}
            </button>
            <button
              className="flex items-center justify-center text-slate-300 hover:text-red-400 border-2 border-slate-600 w-8 h-8 rounded-full hover:border-sky-500 transition-colors"
              onClick={signout}
            >
              <PowerOffIcon width="18" height="18" className="text-inherit" />
            </button>
          </div>
        </nav>
      </div>
    </div>
  );
}
