import moment from "moment";
import { useEffect, useRef, useState, Fragment, useCallback } from "react";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import Loader from "../../components/shared/Loader";
import { useEnv } from "../../context/EnvContext";

import { Menu, Transition } from "@headlessui/react";
import { DotsVerticalIcon } from "@heroicons/react/solid";
import ConfirmModal from "../../components/ConfirmModal";
interface instanceType {
  creationTimestamp: string;
  externalIp: string;
  name: string;
  runningMeetings: number;
  runningMeetingsData: any;
  url: string;
  zone: string;
}

interface actionType {
  type: string;
  serverName: string | null;
  title: string;
  description: string;
}

const DEV_HOST = process.env.REACT_APP_LOADBALANCER_DEV;
const PROD_HOST = process.env.REACT_APP_LOADBALANCER_PROD;

export default function Traffic() {
  const [instances, setInstances] = useState([]);
  const [loader, setLoader] = useState(false);
  const { env } = useEnv();
  const envRef = useRef<string>();
  const [action, setAction] = useState<actionType | null>(null);

  const fetchTraffic = useCallback(async () => {
    if (env !== envRef.current) return;

    let host = envRef.current === "production" ? PROD_HOST : DEV_HOST;

    fetch(host + "/loadbalancer/healthCheck", {
      headers: { Authorization: "Bearer YPHXpC53qD46SAWGVF58RyK4" },
    })
      .then((res) => res.json())
      .then((result) => {
        let data = result.activeInstances;
        if (data) {
          setInstances(data);
        }
        setLoader(false);
      })
      .catch((error) => console.log(error));
  }, [env]);

  const getTimeTillNow = (past: string) => {
    var startTime = moment(past);
    var endTime = moment();

    // calculate total duration
    var duration = moment.duration(endTime.diff(startTime));

    // duration in hours
    var hours = Math.floor(duration.asHours()).toString();
    if (parseInt(hours) < 10) {
      hours = "0" + hours;
    }

    // duration in minutes
    var minutes = Math.floor(duration.asMinutes() % 60).toString();
    if (parseInt(minutes) < 10) {
      minutes = "0" + minutes.toString();
    }

    return `${hours}:${minutes}`;
  };

  const confirmAction = async () => {
    if (action) {
      let host = env === "production" ? PROD_HOST : DEV_HOST;

      let url = host + "/loadbalancer/instances";
      let data = { serverName: action.serverName };
      switch (action.type) {
        case "remove":
          url += "/remove";
          break;
        case "replace":
          url += "/replace";
          break;
        case "replace_all":
          url += "/replaceAll";
          break;
      }

      await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer YPHXpC53qD46SAWGVF58RyK4",
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((result) => {
          console.log(action.type, result);
        })
        .catch((error) => console.log(error));

      setAction(null);
    }
  };

  const promptConfirmation = (type: string, name: string | null) => {
    let action = {
      type: type,
      serverName: name,
      title: "",
      description: "",
    };
    switch (type) {
      case "remove":
        action.title = "Remove confirmation";
        action.description =
          "Are you sure you want to remove the instance? This action cannot be undone.";
        break;
      case "replace":
        action.title = "Replace confirmation";
        action.description =
          "Are you sure you want to replace the instance? This action cannot be undone.";
        break;
      case "replace_all":
        action.title = "Replace all confirmation";
        action.description =
          "Are you sure you want to replace all instances? This action cannot be undone.";
        break;
    }
    setAction(action);
  };

  useEffect(() => {
    envRef.current = env;
    setLoader(true);
    fetchTraffic();
    setInterval(() => fetchTraffic(), 1000 * 30);
  }, [env, fetchTraffic]);

  return (
    <>
      <Sidebar />
      <Header title="Dashboard" />
      {action && (
        <ConfirmModal
          title={action.title}
          description={action.description}
          onCancel={() => setAction(null)}
          onConfirm={() => confirmAction()}
        />
      )}

      <div className="block pl-20 pr-8 ml-2">
        <section className="w-full pb-8">
          <div className="relative flex flex-col min-w-0 break-words w-full shadow-lg rounded bg-slate-900 text-white overflow-hidden">
            <div className="block w-full min-h-screen -mb-28 overflow-x-auto pt-4">
              <div className="sm:flex sm:gap-x-2 sm:justify-between px-4 items-center">
                <div className="flex justify-between items-center px-2 w-full">
                  <h3 className="font-semibold text-lg text-white">Traffic</h3>
                  <button
                    type="button"
                    className="px-6 py-2.5 bg-red-600 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out"
                    onClick={() => promptConfirmation("replace_all", null)}
                  >
                    Replace All
                  </button>
                </div>
              </div>
              {loader ? (
                <Loader />
              ) : (
                <div className="mt-4 flex flex-col">
                  <table className="items-center w-full bg-transparent border-collapse">
                    <thead>
                      <tr className="border border-solid text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-slate-900 border-slate-700 text-gray-300">
                        <th className="px-6 py-3">No.</th>
                        <th className="px-6 py-3">Server Name</th>
                        <th className="px-6 py-3">Zone</th>
                        <th className="px-6 py-3">Spawn Time</th>
                        <th className="px-6 py-3">Server Active Time</th>
                        <th className="px-6 py-3">Active Meeting Id</th>
                        <th className="px-6 py-3">Meeting Time</th>
                        <th className="px-6 py-3">Meeting Active Time</th>
                      </tr>
                    </thead>
                    {instances && instances.length > 0 && (
                      <tbody>
                        {instances.map((instance: instanceType, i) => (
                          <tr
                            className=" whitespace-nowrap text-sm"
                            key={"traffic" + i}
                          >
                            <td className="px-6 py-4">{i + 1}</td>
                            <td className="px-6 py-4">{instance.name}</td>
                            <td className="px-6 py-4">{instance.zone}</td>
                            <td className="px-6 py-4">
                              {instance.creationTimestamp &&
                                moment(instance.creationTimestamp).format(
                                  "DD-MM-YYYY hh:mm:ss A"
                                )}
                            </td>
                            <td className="px-6 py-4">
                              {instance.creationTimestamp &&
                                getTimeTillNow(instance.creationTimestamp)}
                            </td>
                            <td className="px-6 py-4">
                              {instance.runningMeetingsData[0]?.meetingId ??
                                "IDLE"}
                            </td>
                            <td className="px-6 py-4">
                              {instance.runningMeetingsData[0]?.startTime
                                ? moment(
                                    instance.runningMeetingsData[0]?.startTime
                                  ).format("DD-MM-YYYY hh:mm:ss A")
                                : "-"}
                            </td>
                            <td className="px-6 py-4">
                              {instance.runningMeetingsData[0]?.startTime
                                ? getTimeTillNow(
                                    instance.runningMeetingsData[0]?.startTime
                                  )
                                : "-"}
                            </td>
                            <td>
                              <Menu
                                as="div"
                                className="relative inline-block text-left"
                              >
                                <div>
                                  <Menu.Button
                                    className={`inline-flex justify-center w-full text-sm font-medium outline-none text-gray-300`}
                                  >
                                    <DotsVerticalIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </Menu.Button>
                                </div>
                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Menu.Items className="origin-top-right absolute right-0 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                                    <div className="py-1">
                                      <Menu.Item>
                                        <button
                                          className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-sky-700 hover:text-white"
                                          onClick={() =>
                                            promptConfirmation(
                                              "remove",
                                              instance.name
                                            )
                                          }
                                        >
                                          Remove
                                        </button>
                                      </Menu.Item>
                                      <Menu.Item>
                                        <button
                                          className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-sky-700 hover:text-white"
                                          onClick={() =>
                                            promptConfirmation(
                                              "replace",
                                              instance.name
                                            )
                                          }
                                        >
                                          Replace
                                        </button>
                                      </Menu.Item>
                                    </div>
                                  </Menu.Items>
                                </Transition>
                              </Menu>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    )}
                  </table>
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
