import { LockClosedIcon } from "@heroicons/react/outline";
import { useAuth } from "../../context/AuthContext";
import { Navigate } from "react-router-dom";

export default function Approval() {
  const { signout, admin } = useAuth();

  if (admin && admin.approved) {
    return <Navigate to="/" />;
  }

  return (
    <section className="flex justify-center items-center h-screen">
      <div className="mb-8">
        <div className="w-full">
          <h1 className="text-white text-3xl font-bold">
            Your account requires approval!
          </h1>
          <div className="mt-4 text-center">
            <button
              type="button"
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-full shadow-sm text-sm font-medium text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
              onClick={signout}
            >
              <LockClosedIcon
                className="-ml-1 mr-2 h-5 w-5"
                aria-hidden="true"
              />
              Sign Out
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}
