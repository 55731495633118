import { UserGroupIcon, TrendingUpIcon } from "@heroicons/react/solid";
import { useState } from "react";
import Tooltip from "./shared/Tooltip";
import { Link } from "react-router-dom";

export default function Sidebar() {
  const [tooltip, setTooltip] = useState("");
  return (
    <div className="h-screen w-16 bg-slate-900 text-white px-4 flex items-center fixed z-50 shadow-lg shadow-slate-700">
      <div className="absolute top-4 left-3 w-10 h-10">
        <img src="/favicon.png" alt="logo" />
      </div>
      <ul>
        <li className="my-2 md:my-0">
          <Link
            to="/"
            className="flex align-middle items-center py-1 md:py-3 pl-1"
            onMouseEnter={() => setTooltip("users")}
            onMouseLeave={() => setTooltip("")}
          >
            <UserGroupIcon className="w-6 h-6 shrink-0" />
            {tooltip === "users" && <Tooltip text="Users" />}
          </Link>
        </li>
        <li className="my-2 md:my-0">
          <Link
            to="/traffic"
            className="flex align-middle items-center py-1 md:py-3 pl-1"
            onMouseEnter={() => setTooltip("traffic")}
            onMouseLeave={() => setTooltip("")}
          >
            <TrendingUpIcon className="w-6 h-6 shrink-0" />
            {tooltip === "traffic" && <Tooltip text="Traffic" />}
          </Link>
        </li>
      </ul>
    </div>
  );
}
