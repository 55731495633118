import {
  useContext,
  createContext,
  useReducer,
  useEffect,
  ReactNode,
} from "react";

import { doc, setDoc, getDoc } from "firebase/firestore";

import {
  getAuth,
  onAuthStateChanged,
  getIdToken,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
} from "firebase/auth";

import { dbAdmins } from "../config/firebase";
import { getData } from "../utils/fetch";

interface adminType {
  uid: string;
  displayName: string;
  email: string;
  photoUrl: string;
  approved: boolean;
  providerId: string;
}

interface AuthContextType {
  isInitialized: boolean;
  isAuthenticated: boolean;
  admin: adminType | null;
  signInWithGoogle: () => void;
  signout: () => void;
}

let AuthContext = createContext<AuthContextType>(null!);

interface payloadType {
  isInitialized: boolean;
  isAuthenticated: boolean;
  admin: adminType | null;
}

const initialState: payloadType = {
  isInitialized: false,
  isAuthenticated: false,
  admin: null,
};

const reducer = (state: payloadType, action: any) => {
  if (action.type === "INITIALISE") {
    const { isAuthenticated, admin } = action.payload;
    return {
      ...state,
      isInitialized: true,
      isAuthenticated,
      admin,
    };
  }
  return state;
};

export function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const auth = getAuth();
  const provider = new GoogleAuthProvider();

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        let idToken = await getIdToken(user);
        let result = await getData(
          process.env.REACT_APP_API_URL + "/admin/profile",
          idToken
        );
        if (result && result.data) {
          dispatch({
            type: "INITIALISE",
            payload: { isAuthenticated: true, admin: result.data },
          });
        } else {
          dispatch({
            type: "INITIALISE",
            payload: { ...initialState },
          });
        }
      } else {
        dispatch({
          type: "INITIALISE",
          payload: { ...initialState },
        });
      }
    });
  }, [auth]);

  let signInWithGoogle = () => {
    signInWithPopup(auth, provider)
      .then(async (result) => {
        if (result.user) {
          let user = {
            uid: result.user.uid,
            displayName: result.user.displayName,
            email: result.user.email,
            photoUrl: result.user.photoURL,
            approved: false,
            providerId: result.providerId,
          };

          let userRef = await getDoc(doc(dbAdmins, user.uid));
          if (!userRef.exists()) {
            await setDoc(doc(dbAdmins, user.uid), user);
            dispatch({
              type: "INITIALISE",
              payload: { isAuthenticated: true, admin: user },
            });
          }
        }
      })
      .catch((error) => {
        console.log("Firebase Auth Error", error);
      });
  };

  let signout = () => {
    signOut(auth)
      .then(() => {
        dispatch({
          type: "INITIALISE",
          payload: { ...initialState },
        });
      })
      .catch((error) => {
        console.log("Firebase SignOut Error", error);
      });
  };

  return (
    <AuthContext.Provider value={{ ...state, signInWithGoogle, signout }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
