import { useState, createContext, useEffect, useContext } from "react";

type EnvContextType = {
  env: string;
  setEnv: (value: string) => void;
};

// Create context object
export const EnvContext = createContext<EnvContextType>(null!);
const DEFAUL_ENV = process.env.REACT_APP_FIRESTORE_ENV as string;

// Create a provider for components to consume and subscribe to changes
export const EnvProvider = (props: any) => {
  const [env, setEnv] = useState<string>(DEFAUL_ENV);

  useEffect(() => {
    setEnv(DEFAUL_ENV);
  }, []);

  return (
    <EnvContext.Provider value={{ env, setEnv }}>
      {props.children}
    </EnvContext.Provider>
  );
};

export const useEnv = () => useContext(EnvContext);
