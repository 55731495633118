import { Navigate } from "react-router-dom";
import { GoogleIcon } from "../../components/shared/Icons";
import { useAuth } from "../../context/AuthContext";

export default function Login() {
  const { isAuthenticated, signInWithGoogle } = useAuth();

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <section className="flex justify-center items-center h-screen">
      <div className="max-w-md w-full">
        <div className="mb-8">
          <div className="w-40 mx-auto">
            <img src="/logo.png" alt="logo" />
          </div>
        </div>
        <div className="relative">
          <button
            className="flex items-center justify-center mt-4 text-white rounded-lg shadow-md bg-slate-800 hover:bg-opacity-80 w-full"
            onClick={signInWithGoogle}
          >
            <div className="px-4 py-3">
              <GoogleIcon width="34" height="34" />
            </div>
            <h1 className="px-4 py-3 w-5/6 text-center text-gray-300 text-lg font-bold">
              Sign in with Google
            </h1>
          </button>
        </div>
      </div>
    </section>
  );
}
