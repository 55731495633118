export default function Tooltip(props: { text: string }) {
  const { text } = props;
  return (
    <div className="absolute left-full -ml-1">
      <div className="w-32 p-2 text-sm leading-tight text-white  bg-sky-500 rounded-lg shadow-lg">
        {text}
      </div>
      <svg
        className="absolute w-6 h-6 text-sky-500  fill-current stroke-current"
        width="8"
        height="8"
        style={{ left: "-15px", top: "9px" }}
      >
        <rect x="12" y="-10" width="8" height="8" transform="rotate(45)" />
      </svg>
    </div>
  );
}
