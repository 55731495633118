import { BrowserRouter, Routes, Route } from "react-router-dom";

import Login from "./pages/login";
import Dashboard from "./pages/dashboard";
import Approval from "./pages/approval";
import Traffic from "./pages/traffic";

import Private from "./routes/Private";
import { useAuth } from "./context/AuthContext";
import PageLoader from "./components/PageLoader";
import { EnvProvider } from "./context/EnvContext";

function App() {
  const { isInitialized } = useAuth();

  return (
    <BrowserRouter>
      <div className="min-h-screen bg-gradient-to-r from-slate-800 to-slate-900">
        {isInitialized ? (
          <EnvProvider>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route
                path="/approval"
                element={
                  <Private>
                    <Approval />
                  </Private>
                }
              />
              <Route
                path="/"
                element={
                  <Private>
                    <Dashboard />
                  </Private>
                }
              />
              <Route
                path="/traffic"
                element={
                  <Private>
                    <Traffic />
                  </Private>
                }
              />
            </Routes>
          </EnvProvider>
        ) : (
          <PageLoader />
        )}
      </div>
    </BrowserRouter>
  );
}

export default App;
