import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

export default function Private({ children }: { children: JSX.Element }) {
  const { isAuthenticated, admin } = useAuth();
  let location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else {
    if (!admin || !admin.approved) {
      if (location.pathname !== "/approval") {
        return <Navigate to="/approval" state={{ from: location }} replace />;
      }
    }
  }

  return children;
}
