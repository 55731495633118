import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import {
  CheckIcon,
  SelectorIcon,
  CheckCircleIcon,
  LightningBoltIcon,
} from "@heroicons/react/solid";
import { useEnv } from "../context/EnvContext";

const environments = ["production", "development"];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function EnvSelector() {
  const { env, setEnv } = useEnv();

  return (
    <Listbox value={env} onChange={setEnv}>
      {({ open }: any) => (
        <>
          <div className="mt-1 relative">
            <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-1 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-sky-500 focus:border-sky-500 sm:text-sm">
              <span className="flex items-center">
                <CheckCircleIcon className="flex-shrink-0 h-6 w-6 text-sky-600" />
                <span className="ml-3 block truncate capitalize">{env}</span>
              </span>
              <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                {environments.map((environment, i) => (
                  <Listbox.Option
                    key={"env" + i}
                    className={({ active }: any) =>
                      classNames(
                        active ? "text-white bg-sky-600" : "text-gray-900",
                        "cursor-default select-none relative py-2 pl-3 pr-9"
                      )
                    }
                    value={environment}
                  >
                    {({ env, active }: any) => (
                      <>
                        <div className="flex items-center">
                          <LightningBoltIcon className="flex-shrink-0 h-6 w-6" />
                          <span
                            className={classNames(
                              env ? "font-semibold" : "font-normal",
                              "ml-3 block truncate capitalize"
                            )}
                          >
                            {environment}
                          </span>
                        </div>

                        {active ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-sky-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
