import { Fragment, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { DotsVerticalIcon } from "@heroicons/react/solid";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function UserTableAction({ row, handleMakeFree }: any) {
  const [progress, setProgress] = useState("");

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button
          className={`inline-flex justify-center w-full text-sm font-medium outline-none ${
            row.status === "NA" && progress !== row.uid
              ? "text-gray-300"
              : "text-gray-600"
          }`}
        >
          <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>
      {row.status === "NA" && progress !== row.uid && (
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
            <div className="py-1">
              <Menu.Item>
                <button
                  className={classNames(
                    "block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-sky-700 hover:text-white"
                  )}
                  onClick={() => {
                    setProgress(row.uid);
                    handleMakeFree(row.uid);
                  }}
                >
                  Make Free
                </button>
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      )}
    </Menu>
  );
}
